import styled from 'styled-components';
import { CSSVars } from '../../styles/styles.config';

export const StyledDiv = styled.div`
	max-width: 1420px;
	margin: 0 auto;

	h2 {
		font-size: ${CSSVars.fontSize2XL};
		color: ${CSSVars.gray900};
		font-weight: 600;
		text-align: center;
		margin: 0 auto 50px;

		@media screen and (min-width: ${CSSVars.breakpointMD}) {
			font-size: ${CSSVars.fontSize4XL};
		}

		span {
			color: ${CSSVars.primary500};
		}
	}

	.cards {
		display: grid;
		gap: 50px;
		grid-template-columns: 1fr;
		grid-template-rows: 1fr 1fr 1fr;

		@media screen and (min-width: ${CSSVars.breakpointMD}) {
			grid-template-columns: 1fr 1fr;
		}

		@media screen and (min-width: ${CSSVars.breakpointXL}) {
			grid-template-columns: 1fr 1fr 1fr;
		}

		.card {
			.title {
				display: flex;
				align-items: center;
				gap: 20px;
				color: ${CSSVars.gray900};
				margin-bottom: 20px;

				h3 {
					font-weight: 600;
					font-size: ${CSSVars.fontSizeLG};

					@media screen and (min-width: ${CSSVars.breakpointMD}) {
						font-size: ${CSSVars.fontSizeXL};
					}
				}
			}

			p {
				font-weight: 300;
				font-size: ${CSSVars.fontSizeMD};
				
				/* @media screen and (min-width: ${CSSVars.breakpointMD}) {
					font-size: ${CSSVars.fontSizeLG};
				} */
			}
		}
	}
`;
