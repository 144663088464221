'use client';
import React from 'react';
import { StyledDiv } from './testimonials.styles';
import { Section } from '../section/section.comp';
import { CommonNinjaWidget } from 'commonninja-react';

export const Testimonials = ({
	mode = 'light',
}: {
	mode: 'light' | 'dark';
}) => {
	// For now, because TrustPilot are a 💩 company.
	return <></>;

	// return (
	// 	<Section mode={mode}>
	// 		<StyledDiv className={mode}>
	// 			<h2>What Our Users Think</h2>
	// 			<CommonNinjaWidget widgetId={'bc44d510-2992-404a-9da7-cec299d012db'} />
	// 		</StyledDiv>
	// 	</Section>
	// );
};
